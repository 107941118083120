import { rgba } from "polished";
const colors = {
    surfaceError: '#fb695e',
    textError: '#fb695e',
    surfacePromo: '#FF7438',
    textPromo: '#FF7438',
    surfaceAttention: '#ffc000',
    textAttention: '#ffc000',
    surfaceBrand: '#008DF2',
    textBrand: '#008DF2',
    textOnBrand: '#FFF',
    surfaceSuccess: '#1EBD46',
    textSuccess: '#1EBD46',
    surfaceCard: '#202B36',
    surfaceLine: 'rgba(49, 60, 71, 0.5)',
    textMain: 'rgba(250,252,255,0.85)',
    textSecondary: '#838B8F',
    surfaceIcon: '#838B8F',
    surfaceBorder: '#313C47',
    surfaceBackground: '#171F26',
    surfaceBackgroundSecondary: '#283847',
    surfaceBackgroundAlternate: rgba('#151F28', 0.5),
    surfaceDisabled: rgba('#202B36', 0.7),
};
const backgrounds = {
    lightGrey: '#3F5060',
    checkbox: '#fafcff',
    input: '#151F28',
    mobileButton: '#222F3B'
};
const borders = {
    button: '#3F5060',
    checkbox: '#3F5060',
    whiteBlueButton: '#3F5060',
};
const defaultShadow = {
    S: `0px 1px 2px 0px rgba(13, 22, 31, 0.40)`,
    M: '0px 8px 32px 0px #0D161F',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: '#3F5060',
    backgroundColor: '#222F3B',
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const botsNightTheme = {
    isDark: true,
    colors,
    backgrounds,
    borders,
    loginBackground: '#151F28',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, botsNightTheme), { header: whiteHeader });
