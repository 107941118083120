import styled, { css } from "styled-components";
import { color } from "./themes";
const titleTextStyle = css `
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 650;
  line-height: 24px;
  
  color: ${color("textMain")};
`;
const TitleText = styled.p.withConfig({ displayName: "TitleText", componentId: "sc-4ljf3s" }) `
  ${titleTextStyle};
`;
export { TitleText, titleTextStyle };
