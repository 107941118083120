var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { stringify } from "query-string";
import { useEffect } from 'react';
import { fillEndpointTemplate } from './fill-endpoint-template';
window.axios = axios;
export const saveCsrfCookie = (ignoreSaved = false) => {
    if (document.cookie.includes('XSRF-TOKEN') && !ignoreSaved) {
        return;
    }
    return axios.get('/api/csrf-cookie');
};
export const useCSRFToken = () => {
    useEffect(() => {
        saveCsrfCookie();
    }, []);
};
export function request(method, httpMethod = "GET") {
    var _a;
    const sendParamsInBody = ["POST", "PUT", "PATCH"].indexOf(httpMethod.toUpperCase()) !== -1;
    let isTablet = false;
    try {
        isTablet = ((_a = window === null || window === void 0 ? void 0 : window.matchMedia("(max-width: 768px)")) === null || _a === void 0 ? void 0 : _a.matches) || false;
    }
    catch (e) {
    }
    if (method.indexOf("/") === 0) {
        console.error("Api calls can't start with /", { method });
    }
    const doRequest = (params = {}, routeParams = {}, domain = null, attempt = 1, headerConfig = {}, config = {}) => {
        return axios
            .request(Object.assign({ method: httpMethod, url: `${domain || window.location.origin}/${process.env.API_PREFIX || 'api'}${method === '?json' ? '' : '/'}${fillEndpointTemplate(method, routeParams)}`, data: sendParamsInBody ? params : undefined, params: sendParamsInBody ? undefined : params, headers: Object.assign(Object.assign(Object.assign({ "X-Site-Host": window.location.host }, (process.env.NODE_ENV !== 'production' ? {
                'csrf-skip-key': 'aMscRcQRCK',
            } : {})), (isTablet ? {
                'X-Tablet': true,
            } : {})), headerConfig) }, config))
            .then((response) => response.data)
            .catch((error) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status === 419 && attempt < 3) {
                yield saveCsrfCookie(true);
                return doRequest(params, routeParams, domain, attempt + 1, config);
            }
            throw error;
        }));
    };
    return doRequest;
}
export function openWindow(method, options, openParams = null) {
    const query = options ? `?${stringify(options, { arrayFormat: 'bracket' })}` : "";
    const url = `${window.location.origin}${process.env.PUBLIC_URL || '/'}api/${method}${query}`;
    return window.open(url, openParams);
}
export const windowRequest = (method, params = {}) => {
    return new Promise((resolve) => {
        const popup = openWindow(method, params, '_blank');
        const listener = (message) => {
            const { data } = message;
            resolve(data);
            window.removeEventListener('message', listener);
        };
        const iterate = () => {
            setTimeout(() => {
                if (popup && popup.closed) {
                    resolve();
                }
                else {
                    iterate();
                }
            }, 300);
        };
        iterate();
        window.addEventListener('message', listener);
    });
};
