import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { shallowEqual } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Spinner } from "@socgress/ui/loaders";
import { ModalWrapper } from "./modal-wrapper";
import { CenteredModalContent } from "./centered-modal-content";
import { Desktop, Tablet } from '../media';
import { ModalMobileGlobalStyle } from '../modal/template';
const modalHeight = window.innerHeight;
const BottomSheet = lazy(() => import(/* webpackChunkName: "bottom-sheet" */ './bottom-sheet'));
class ModalSystemContainer extends Component {
    constructor() {
        super(...arguments);
        this.overlayRef = null;
        this.state = {
            ActiveModal: null,
            activeModalCtor: null,
            activeModalProps: null,
            isLoaded: false,
            isClosing: false,
        };
        this.handleCloseRequested = (data = null) => {
            const { isAsync, close, closeAsync } = this.props;
            const { activeModalProps } = this.state;
            if (activeModalProps && typeof activeModalProps.onClose === "function") {
                activeModalProps.onClose(data);
            }
            if (isAsync) {
                closeAsync(data);
            }
            else {
                close();
            }
        };
        this.overlayRendered = (overlay) => {
            if (overlay) {
                this.overlayRef = overlay;
                disableBodyScroll(overlay);
            }
            else {
                enableBodyScroll(this.overlayRef);
            }
        };
        this.onModalClosing = () => {
            this.setState(state => (Object.assign(Object.assign({}, state), { isClosing: !state.isClosing })));
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const { modalCtor, props } = nextProps;
        const { activeModalCtor, activeModalProps } = prevState;
        const isModalUpdated = !matchModals(activeModalCtor, activeModalProps)(modalCtor, props);
        if (isModalUpdated) {
            return {
                ActiveModal: modalCtor === null ? modalCtor : dynamic(modalCtor),
                activeModalCtor: modalCtor,
                activeModalProps: props,
                isLoaded: false,
            };
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        const { activeModalCtor, isLoaded, ActiveModal, activeModalProps } = this.state;
        const isModalUpdated = ActiveModal && (activeModalCtor !== prevState.activeModalCtor
            || (activeModalCtor === prevState.activeModalCtor && !shallowEqual(activeModalProps, prevState.activeModalProps)));
        if (isModalUpdated && !isLoaded) {
            ActiveModal.render
                .preload()
                .then(() => this.setState((state) => state.activeModalCtor === activeModalCtor && { isLoaded: true }));
        }
    }
    componentWillUnmount() {
        const { isVisible } = this.props;
        if (isVisible) {
            enableBodyScroll(this.overlayRef);
        }
    }
    render() {
        const { isVisible, isOpenedFromStack, overlayElement } = this.props;
        const ModalOverlay = overlayElement || CenteredModalContent;
        const { ActiveModal, activeModalProps, isLoaded, isClosing } = this.state;
        const modalStatus = (ActiveModal && isLoaded && "complete") ||
            (ActiveModal && !isLoaded && "loading") ||
            "closed";
        return (React.createElement(React.Fragment, null,
            React.createElement(Desktop, null,
                React.createElement(ModalWrapper, { isOpen: isVisible, closeTimeoutMS: 500, overlayRef: this.overlayRendered, onRequestClose: () => this.handleCloseRequested() },
                    React.createElement(ModalOverlay, Object.assign({}, activeModalProps, { modal: ActiveModal, modalStatus: modalStatus, isOpenedFromStack: isOpenedFromStack, onOverlayClick: () => this.handleCloseRequested() }),
                        modalStatus === "loading" && React.createElement(Spinner, { color: "#fff" }),
                        modalStatus === "complete" && (React.createElement(ActiveModal, Object.assign({}, activeModalProps, { onClose: this.handleCloseRequested, isOpenedFromStack: isOpenedFromStack })))))),
            React.createElement(Tablet, null,
                React.createElement(ModalMobileGlobalStyle, { isClosing: isClosing, isVisible: isVisible, hasCustomHeader: !!(activeModalProps === null || activeModalProps === void 0 ? void 0 : activeModalProps.mobileHeader) }),
                React.createElement(Suspense, { fallback: null },
                    React.createElement(BottomSheet, { open: isVisible, snapPoints: (activeModalProps === null || activeModalProps === void 0 ? void 0 : activeModalProps.snapPoints) ? () => activeModalProps === null || activeModalProps === void 0 ? void 0 : activeModalProps.snapPoints : () => [modalHeight * 0.9], onDismiss: () => setTimeout(() => this.handleCloseRequested(), 80), initialFocusRef: false, header: (activeModalProps === null || activeModalProps === void 0 ? void 0 : activeModalProps.mobileHeader) ? activeModalProps.mobileHeader({ onClose: () => this.handleCloseRequested() }) : null },
                        modalStatus === "loading" && React.createElement(Spinner, { color: "#fff" }),
                        modalStatus === "complete" && (React.createElement(ActiveModal, Object.assign({}, activeModalProps, { onClose: this.handleCloseRequested, isOpenedFromStack: isOpenedFromStack }))))))));
    }
}
ModalSystemContainer.propTypes = {
    close: PropTypes.func.isRequired,
    closeAsync: PropTypes.func.isRequired,
    modalCtor: PropTypes.func,
    isVisible: PropTypes.bool,
    props: PropTypes.shape({}),
    isAsync: PropTypes.bool,
    isOpenedFromStack: PropTypes.bool,
    overlayElement: PropTypes.elementType,
};
ModalSystemContainer.defaultProps = {
    modalCtor: null,
    isVisible: false,
    props: null,
    isAsync: false,
    isOpenedFromStack: false,
    overlayElement: null,
};
export { ModalSystemContainer };
const matchModals = (ctor, props) => (nextCtor, nextProps) => {
    const isShallowMatch = ctor === nextCtor && shallowEqual(props, nextProps);
    if (isShallowMatch) {
        // my own deep equal for backend Notification events
        return props && props.notification
            ? props.notification.id === nextProps.notification.id
            : isShallowMatch;
    }
    return isShallowMatch;
};
