import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { borderRadius, color } from '@socgress/ui/themes';
import { commonMedia } from '../../common-media';
import { mediumTextStyle } from '@socgress/ui/medium-text';
const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-6ef89d" }) `
  @media(max-width: 768px) {
    overflow-y: auto;
  }
`;
const Table = styled.table.withConfig({ displayName: "Table", componentId: "sc-7rpv49" }) `
  width: 100%;
  
  @media(max-width: 768px) {
    width: ${prop('width', '100%')};
  }
`;
const TableBody = styled.div.withConfig({ displayName: "TableBody", componentId: "sc-1wtctma" }) `
  & > :first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
const TableHeader = styled.div.withConfig({ displayName: "TableHeader", componentId: "sc-1tun5gc" }) `
  z-index: 999;
  
  border-top-left-radius: ${borderRadius()}px;
  border-top-right-radius: ${borderRadius()}px;

  ${ifProp("isSticky", css `
      top: 0;

      ${commonMedia.lessThan("tablet") `
        top: 60px; 
        `};
    `)};
  
  ${ifProp('hasHover', css `
    @media(min-width: 769px) {
      tr {
        padding: 0 22px;
      }
    }
  `)};
`;
const TableRow = styled.tr.withConfig({ displayName: "TableRow", componentId: "sc-1uua94e" }) `
  display: flex;
  align-items: stretch;
  min-height: 51px;

  padding: 0 24px;
`;
const tableCellStyle = css `
  text-align: left;
`;
const TableHeaderCell = styled.th.withConfig({ displayName: "TableHeaderCell", componentId: "sc-1tqhyfy" }) `
  ${tableCellStyle};
  ${mediumTextStyle};

  display: flex;
  align-items: center;

  white-space: pre-wrap;

  padding: 16px 0;

  color: ${color('textMain')};
`;
const TableCell = styled.td.withConfig({ displayName: "TableCell", componentId: "sc-k9w9aq" }) `
  ${tableCellStyle};
  padding: 20px 0;

  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  tr:not(:last-of-type) & {
    border-bottom: 1px solid ${color("surfaceLine")};
  }
`;
//#region CenterContent
const CenterContent = styled.div.withConfig({ displayName: "CenterContent", componentId: "sc-uejjsf" }) `
  display: flex;
  justify-content: center;
  flex-flow: column;

  height: 100%;
`;
//#endregion
export { TableContainer, Table, TableHeader, TableBody, TableCell, TableHeaderCell, TableRow, CenterContent };
