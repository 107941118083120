import React, { useEffect, useRef, useState, } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import { HeaderLink } from './header-link';
import { commonMedia, useIsDesktop, useIsTablet, } from '@socgress/lib/common-media';
import { MenuGroup, MenuItem, } from './mobile-menu';
import { Brand } from '../brand';
import { useTranslation } from 'react-i18next';
import { useEventListener } from '@socgress/lib/hooks';
import { color } from '@socgress/ui/themes';
import { Button } from '@socgress/ui/button';
import { zord } from '@socgress/lib/zord';
function Menu({ handleIsMenuOpen, menuIcon, t }) {
    return (React.createElement(MenuButton, null,
        React.createElement(MenuItem, { hideArrow: true, hideBackroundOnMobile: true, isReverse: true, width: 18, onClick: (e) => {
                e.preventDefault();
                setTimeout(() => {
                    handleIsMenuOpen((isVisible) => !isVisible);
                }, 1);
            }, icon: menuIcon, href: "/app", rightPaddingDisabled: true }, t('menu'))));
}
function usePreventScroll(preventScrollRef) {
    useEffect(() => {
        const preventScrolling = e => {
            if (preventScrollRef.current) {
                e.preventDefault();
            }
        };
        document.addEventListener('touchmove', preventScrolling, {
            passive: false,
        });
        return () => document.removeEventListener('touchmove', preventScrolling);
    }, []);
}
const Header = ({ logo: Logo, isMobileFixed, brandName, desktopMenu, mobileMenu, desktopLinks, children, maxWidth, menuIcon, logoHref, showMenuButtonWhenEmpty, desktopLinksLeftMargin, onMobileMenuClick, logoLinkElement, menuRightElement, mobileRight }) => {
    const preventScrollRef = useRef(false);
    usePreventScroll(preventScrollRef);
    const [modalHeight] = useState(window.innerHeight * 0.9);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const mobileMenuContainerRef = useRef();
    const isTablet = useIsTablet();
    const [t] = useTranslation('common');
    const isDesktop = useIsDesktop();
    const showMenuButton = (isDesktop && (desktopMenu || showMenuButtonWhenEmpty)) || (isTablet && mobileMenu);
    const onToggleMobileMenu = (callback) => {
        if (onMobileMenuClick) {
            onMobileMenuClick({
                isMenuOpen,
                setIsMenuOpen,
                event: null,
                callback,
            });
            return;
        }
        setIsMenuOpen(callback);
    };
    useEventListener(window, 'click', (event) => {
        if (!isMenuOpen) {
            return;
        }
        if (onMobileMenuClick) {
            onMobileMenuClick({
                isMenuOpen,
                setIsMenuOpen,
                event
            });
            return;
        }
        setIsMenuOpen(false);
    });
    const DesktopMenuContainer = desktopMenu || React.Fragment;
    const MenuRightElement = menuRightElement || MenuRight;
    return (React.createElement("header", null,
        React.createElement(StyledHeader, { isMobileFixed: isMobileFixed },
            React.createElement(Container, { maxWidth: maxWidth },
                React.createElement(HeaderLink, { as: logoHref === '#' ? 'div' : logoLinkElement, href: logoHref || '/' }, typeof Logo === 'string' ? React.createElement(Brand, { logo: Logo, brandName: brandName }) : Logo),
                React.createElement(DesktopLinksContainer, { customLeft: desktopLinksLeftMargin },
                    desktopLinks,
                    React.createElement(MenuRightElement, null,
                        children,
                        showMenuButton && isDesktop && (React.createElement(DesktopMenuContainer, null,
                            React.createElement(HoverableButton, null,
                                React.createElement(MenuIcon, { icon: menuIcon })))))),
                React.createElement(Right, null,
                    mobileRight,
                    showMenuButton && isTablet && React.createElement(Menu, { handleIsMenuOpen: () => setIsMenuOpen(true), t: t }))))));
};
Header.defaultProps = {
    isMobileFixed: true,
};
export { Header };
export const StyledHeader = styled.div.withConfig({ displayName: "StyledHeader", componentId: "sc-199qgjg" }) `
  z-index: 100;

  background: ${theme('header.bgColor')};
  box-shadow: ${theme('header.boxShadow')};

  display: flex;

  max-width: 100%;
  justify-content: space-between;
  width: 100%;

  ${commonMedia.lessThan('tablet') `
    ${ifProp('isMobileFixed', css `
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              z-index: 2;
            `)};
  `};
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-4w4ktd" }) `
  display: flex;
  align-items: center;
  
  height: 60px;

  width: 100%;
  max-width: 100%;
  padding: 0;

  @media(max-width: 768px) {
    padding: 0 0 0 15px;
    margin-top: 0;
  }
`;
export const DesktopLinksContainer = styled.div.withConfig({ displayName: "DesktopLinksContainer", componentId: "sc-1nlr30p" }) `
  margin-left: ${prop('customLeft', 30)}px;
  width: 100%;

  display: flex;
  align-items: center;

  height: inherit;

  ${commonMedia.lessThan('tablet') `
    display: none;
  `};
`;
export const Right = styled.div.withConfig({ displayName: "Right", componentId: "sc-11vrjhh" }) `
  margin-left: auto;

  &:not(:empty) {
    padding-left: 20px; // if links container too wide
  }
`;
const MenuButton = styled(MenuGroup).withConfig({ displayName: "MenuButton", componentId: "sc-qlx0kg" }) `
  background-color: transparent;
`;
const MenuRight = styled(Right).withConfig({ displayName: "MenuRight", componentId: "sc-do91e" }) `
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 25px;
  }
`;
const MenuHeader = styled.div.withConfig({ displayName: "MenuHeader", componentId: "sc-1xxme7u" }) `
  background-color: ${color('surfaceBackground')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px 0;
  position: absolute;
  top: -2px;
  touch-action: pan-y;
  z-index: 100;

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 36px;
    height: 4px;
    left: 50%;
    border-radius: 2px;
    transform: translateX(-50%) scaleY(0.75);
    background-color: ${color('surfaceBrand')};
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`;
const MenuContainer = styled.div.withConfig({ displayName: "MenuContainer", componentId: "sc-frlvlg" }) `
  z-index: 102;

  -webkit-overflow-scrolling: touch;
`;
const HoverableButton = styled(Button).attrs({
    buttonTheme: 'white',
    withBorder: true,
}).withConfig({ displayName: "HoverableButton", componentId: "sc-sgyofc" }) `
  padding: ${zord(3)};
`;
const MenuIcon = styled.div.withConfig({ displayName: "MenuIcon", componentId: "sc-1acl0lw" }) `
  width: 20px;
  height: 20px;

  mask: url('${prop('icon')}') no-repeat;
  mask-size: contain;
  transition: background-color 200ms ease-in-out;
  background-color: ${color('surfaceBrand')};
`;
