import styled, { css } from "styled-components";
import { ifNotProp, ifProp, prop, theme } from 'styled-tools';
import { commonMedia } from "@socgress/lib/common-media";
import { zord } from "@socgress/lib/zord";
import { color } from "../../../themes";
import { MainText } from '@socgress/ui/main-text';
export function ItemDivider() {
    return css `
    &:not(:first-child) {
      &:after {
        content: "";
        position: absolute;

        height: 1px;
        top: 0;
        left: 45px;
        right: 0;
        width: 100%;
        background-color: ${color("surfaceLine")};
      }
    }
  `;
}
function NavigationArrow() {
    return css `
    &:after {
      content: '';

      width: 33px;
      position: absolute;
      right: -13px;
      top: 0;
      bottom: 0;

      mask: url(${require('./arrow.svg')}) no-repeat 50%;
      mask-size: 6px 12px;

      background-color: #c3cdd9;
    }
  `;
}
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1t0o2yr" }) `
  flex: 1;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  font-size: 14px;
  font-weight: 500;

  & > ${MainText} {
    flex: 1 1 auto;
  }
  
  ${ifProp("rightPaddingDisabled", "", css `
      @media(min-width: 768px) {
        padding-right: 33px;
      }
    `)}

  position: relative;

  ${commonMedia.lessThan("tablet") `
      ${ifNotProp('hideArrow', NavigationArrow)};
  `};
`;
export const linkHoverCss = (hoverIcon = true) => css `
  ${hoverIcon ? css `
    & > ${Icon} {
      background-color: ${theme("colors.surfaceBrand")};
    }
  ` : ''}

  & > div > ${Title} {
    color: ${theme("colors.textMain")};
  }
`;
export const NavigationLink = styled.div.withConfig({ displayName: "NavigationLink", componentId: "sc-1yu1bow" }) `
  display: flex;
  flex-flow: row nowrap;

  width: 100%;
  position: relative;

  ${commonMedia.greaterThan("tablet") `
      margin: ${zord(5, 0)};
`};

  appearance: none;
  text-decoration: none;
  border: 0;

  transition: background-position 0.15s;

  background: ${theme('backgrounds.mobileButton')};

  background-size: 200%;


  @media(max-width: 768px) {
    &:active {
      background-position: 100%;
    }
  }

  ${commonMedia.greaterThan('tablet') `
    &:hover {
      ${p => linkHoverCss(!p.customIcon)};
    }
  `};
  
  &.active {
    ${p => linkHoverCss(!p.customIcon)};
  }
`;
export const Icon = styled.div.withConfig({ displayName: "Icon", componentId: "sc-kgss99" }) `
  width: ${prop('size', 20)}px;
  height: ${prop('size', 20)}px;
  flex: 0 0 ${prop('size', 20)}px;
  
  ${ifProp('isReverse', css `
    margin-left: ${zord(3)};
  `, css `
    margin-right: ${zord(3)};
  `)};

  transition: background-color 200ms ease;


  ${ifProp("iconSrc", css `
      ${p => p.iconSrc.includes('svg') && !p.isNotMask ? css `
        mask: url('${prop("iconSrc")}') no-repeat 50%;
        mask-size: contain;
        background-color: ${(props) => props.color || props.theme.colors.surfaceIcon};
      ` : css `
        background: url('${prop("iconSrc")}') no-repeat 50%;
        background-size: contain;
      `};
    `)};
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1gl1za9" }) `
  flex: 1 1 auto;
  white-space: nowrap;
  color: ${(props) => props.color || props.theme.colors.textMain};
  
  transition: color 200ms ease;
`;
export const OptionalAppend = styled.div.withConfig({ displayName: "OptionalAppend", componentId: "sc-1ta1ogy" }) `
  flex: 0 1 auto;

  color: ${(props) => props.color};
  padding-right: 2em;
`;
