import styled, { css } from "styled-components";
import { color } from "./themes";
export const smallTextStyle = css `
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 16px;

  color: ${color("textSecondary")};
  white-space: pre-wrap;
`;
export const SmallText = styled.p.withConfig({ displayName: "SmallText", componentId: "sc-s9dud6" }) `
  ${smallTextStyle};
`;
