import styled, { css } from "styled-components";
import { color } from "./themes";
export const mainTextStyle = css `
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  
  white-space: pre-wrap;
  
  color: ${color("textMain")};

  margin: 0;
`;
export const MainText = styled.p.withConfig({ displayName: "MainText", componentId: "sc-hdnz0y" }) `
  ${mainTextStyle};
`;
