import styled, { css } from "styled-components";
import { color } from "./themes";
export const bigTextStyle = css `
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  color: ${color("textMain")};
  white-space: pre-wrap;
`;
export const BigText = styled.p.withConfig({ displayName: "BigText", componentId: "sc-pxo45l" }) `
  ${bigTextStyle};
`;
