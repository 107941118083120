var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { color } from '@socgress/ui/themes';
import { useDebounce, useFirstRun } from '@socgress/lib/hooks';
import { ifProp } from 'styled-tools';
const Switch = (_a) => {
    var { defaultValue, onChange, controlRef = null, labels = {}, labelStyle = {}, isVisible = true, canChange = true } = _a, props = __rest(_a, ["defaultValue", "onChange", "controlRef", "labels", "labelStyle", "isVisible", "canChange"]);
    const [isChecked, setChecked] = useState(() => defaultValue);
    const debouncedChecked = useDebounce(isChecked, 100);
    const isFirstRun = useFirstRun();
    useEffect(() => {
        if (isFirstRun) {
            return;
        }
        onChange(debouncedChecked);
    }, [debouncedChecked]);
    useEffect(() => {
        // @ts-ignore
        controlRef === null || controlRef === void 0 ? void 0 : controlRef.current = {
            setChecked,
        };
    }, []);
    return (React.createElement(SwitchContainer, Object.assign({}, props, { isVisible: isVisible }),
        React.createElement(Container, { onClick: () => canChange ? setChecked(prevChecked => !prevChecked) : onChange(null) },
            React.createElement(Slider, { className: isChecked ? 'checked' : null, isChecked: isChecked })),
        (labels === null || labels === void 0 ? void 0 : labels.on) && (React.createElement(SwitchLabel, { style: labelStyle }, isChecked ? labels.on : labels.off))));
};
export { Switch, SwitchContainer };
const SwitchContainer = styled.div.withConfig({ displayName: "SwitchContainer", componentId: "sc-15ygsts" }) `
  display: flex;
  align-items: center;
  opacity: ${ifProp('isVisible', 1, 0)};

  transition: opacity 200ms ease;
`;
const Slider = styled.span.withConfig({ displayName: "Slider", componentId: "sc-1v2tvd6" }) `
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${color('surfaceBorder')};
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  ${ifProp('isChecked', css `
    &:before {
      transform: translateX(20px);
    }

    & {
      background-color: ${color('surfaceSuccess')};
    }
  `)}
`;
const Container = styled.label.withConfig({ displayName: "Container", componentId: "sc-1snhwlt" }) `
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
`;
const SwitchLabel = styled.div.withConfig({ displayName: "SwitchLabel", componentId: "sc-nx2o8y" }) `
  margin-left: 10px;
  color: ${color('textSecondary')};
`;
