/* export function color(
  name: keyof Colors
): <Props, Theme extends { [key: string]: any }>(
  props: Props & {
    theme: Theme & {
      colors: Colors;
    };
  }
) => Color; */
export function color(name) {
    return ({ theme }) => {
        return theme.colors[name];
    };
}
export function borderRadius(defaultBorder = 20) {
    return () => {
        return 20;
    };
}
