import styled from "styled-components";
import { color } from '@socgress/ui/themes';
import { prop } from 'styled-tools';
export const ToastIcon = styled.div.withConfig({ displayName: "ToastIcon", componentId: "sc-ik4wm" }) `
  min-width: 20px;
  width: 20px;
  height: 20px;
  mask: url('${prop('icon')}');
  background-color: ${prop('color')};
`;
export const Title = styled.p.withConfig({ displayName: "Title", componentId: "sc-iv0hlo" }) `
  color: ${color('textBrand')};
  font-size: 13px;
  padding-right: 25px;
`;
