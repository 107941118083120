import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import PropTypes from 'prop-types';
import { color } from '../../themes/color';
import { rgba } from 'polished';
const InputStyle = css `
  width: 100%;
  padding: 0 12px;
  
  border: 1px solid ${color('surfaceBorder')};
  box-shadow: ${theme('shadow.S')};
  box-sizing: border-box;

  background: ${p => rgba(color('surfaceBackgroundSecondary')(p), 0.5)};

  border-radius: 12px;
  height: 48px;

  color: ${color('textMain')};

  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  transition: all 0.15s ease-in-out, box-shadow 0.3s ease, border-color 0.3s ease;

  &:focus {
    border-color: ${color('surfaceBrand')};
    box-shadow: 0 0 0 2px ${p => rgba(p.theme.colors.surfaceBrand, 0.2)};
    border-right-width: 1px!important;
    outline: 0;
  }

  &[disabled],
  &[readonly],
  &[readonly="readonly"] {
    border: 1px solid ${color('surfaceBorder')};
    background-color: ${p => color(p.theme.isDark ? 'surfaceDisabled' : 'surfaceBackground')};
    opacity: 1; // for mobile safari
  }

  &[readonly] &,
  &[readonly="readonly"] & {
    color: ${p => p.theme.isDark ? '#fff' : color('textMain')(p)};
    -webkit-text-fill-color: ${p => p.theme.isDark ? '#fff' : color('textMain')(p)}; // for safari
  }

  &[disabled] & {
    color: ${color('textSecondary')};
    -webkit-text-fill-color: ${color('textSecondary')}; // for safari
  }

  &::placeholder {
    color: ${color('textSecondary')};
    opacity: 1;
    font-weight: 500;
  }

  &:focus::placeholder {
    opacity: 0.6;
  }

  filter: none; // autofill

  ${({ theme }) => theme.isDark && css `
    &:focus {
      outline: none;
      border: 1px solid rgb(96, 153, 250);
    }
  `};

  ${(props) => props.hasError &&
    css `
            border-color: ${color('surfaceError')};
            color: ${color('surfaceError')};

            &::placeholder {
              color: ${color('surfaceError')};
            }
          `};
`;
const InputBase = styled.input.withConfig({ displayName: "InputBase", componentId: "sc-hxoq11" }) `
  ${InputStyle};

  &:invalid {
    box-shadow: none;
    border-color: ${color('surfaceError')};
  }
`;
InputBase.propTypes = {
    readOnly: PropTypes.bool,
};
InputBase.defaultProps = {
    readOnly: false,
};
export { InputBase, InputStyle };
