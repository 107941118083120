import { createAction } from 'redux-act';
import { supportApi } from '@socgress/lib/support/api';
import { hideModal, showModal } from '../../modal-system/store';
import { commonModals } from '../../modal/common-modals';
import { EchoRegistry } from '../../echo';


/**
 * Every dialog action must be dispatched with `dialogId` or `dialog` object
 * then action.meta.dialogId will be available for supportReducer through metaReducer
 * @param description
 * @returns {ComplexActionCreator1<unknown, unknown, {dialogId: (*)}>}
 */
const createDialogAction = (description) => createAction(description, null, dialogIdMetaReducer);

const dialogIdMetaReducer = (payload) => ({
    dialogId: payload.dialog ? payload.dialog.id : payload.dialogId,
});

/**
 * Dialog actions
 */
export const createDialog = createDialogAction('Create dialog in store');
export const dialogLoaded = createDialogAction('Dialog loaded');
export const dialogCreated = createDialogAction('Dialog created');
export const dialogOnListUpdated = createDialogAction('Dialog updated'); // TODO: temp, make separate reducer for dialogs list
export const messagesLoaded = createDialogAction('Dialog messages loaded');
export const startDialogLoading = createDialogAction('Start dialog loading');
export const addMessageToDialog = createDialogAction('Add message to dialog');
export const updateMessageIds = createDialogAction('Update stored message ids');
export const updateMessage = createDialogAction('Update message by messageId');
export const removeMessage = createDialogAction('Remove message from dialog');
export const updateTyping = createDialogAction(
    'Updates dialog typing objects, sets name and isTyping',
);
export const changeDialogStatus = createDialogAction('Updates dialog status');
export const dialogUpdated = createDialogAction('Dialog updated');
export const socialAccountDetached = createDialogAction(
    'Updates user and sets certain social account to undefined',
);

const buildSendMessage = (sendMessage) => ({...args}) => async (
    dispatch,
    getState,
) => {
    const {text, dialogId, is_formatted, attachments, ...rest} = args;

    const createdAt = new Date().getTime();
    const uniqueId = -createdAt;

    const {
        auth: {user},
    } = getState();

    dispatch(
        addMessageToDialog({
            dialogId,
            message: {
                id: uniqueId,
                text,
                created_at: Math.floor(createdAt / 1000),
                dialog_id: dialogId,
                user,
                is_formatted,
                attachments,
            },
        }),
    );

    dispatch(dialogAnswered({dialogId}));

    dispatch(dialogOnListUpdated({
        dialog: {
            id: dialogId,
            hello_ivan: 0,
        }
    }));

    const formData = new FormData();
    formData.append('text', text);
    formData.append('is_formatted', is_formatted);
    attachments.forEach(file => formData.append('attachments[]', file));

    const doRequest = (captcha = null) => {
        if (captcha) {
            formData.set('captcha', captcha);
        }

        return sendMessage(formData, {dialogId, ...rest})
            .then((sentMessage) => {
                dispatch(
                    updateMessage({
                        dialogId,
                        messageId: uniqueId,
                        message: sentMessage,
                    }),
                );
            });
    }

    let sendPromise;

    try {
        sendPromise = await doRequest();
    } catch (e) {
        const { error } = e.response ? e.response.data : {};

        if (error === 'need_captcha') {
            await new Promise(resolve => {
                dispatch(showModal(commonModals.captcha, {
                    onCaptchaDone: async value => {
                        sendPromise = await doRequest(value);
                        resolve()
                    }
                }))
            });
        }

        return sendPromise;
    }

    return sendPromise;
}

const buildLoadDialog = getDialog => (...args) => dispatch => {
    dispatch(createDialog({dialogId: 0,}));

    return getDialog(...args)
        .then((dialog) => dispatch(dialogLoaded({dialog}, {dialogId: 0,})));
}

export const buildGetDialogMessages = getDialogMessages => (...args) => (dispatch) => {
    dispatch(startDialogLoading({dialogId: 0}));

    const offset = args[3];

    return getDialogMessages(...args).then((messages) => dispatch(
        messagesLoaded({
            dialogId: 0,
            messages,
            offset,
        }),
    ));
};

export const buildGetLastDialogs = getLastDialogs => (...args) => (dispatch, getState) => {
    if (parseInt(args[0], 10) === 0 && getState().supportReducer.newDialogs.items.length > 0) {
        return Promise.resolve();
    }

    return getLastDialogs({offset: args[0]}, {panelId: args[1]}).then((dialogs) => {
        dispatch(lastDialogsLoaded({dialogs}));
    });
}

export const sendPanelMessage = buildSendMessage(supportApi.panelSendMessage);
export const sendMessage = buildSendMessage(supportApi.sendMessage);
export const sendSocpanelMessage = buildSendMessage(supportApi.sendSocpanelMessage);

export const loadDialog = buildLoadDialog(supportApi.getDialog);
export const loadSocpanelDialog = buildLoadDialog(supportApi.getSocpanelDialog);
export const loadPanelDialog = buildLoadDialog(supportApi.getPanelDialog);

export const getPanelDialogMessages = buildGetDialogMessages(supportApi.getPanelMessages);
export const getDialogMessages = buildGetDialogMessages(supportApi.getMessages);
export const getSocpanelDialogMessages = buildGetDialogMessages(supportApi.getSocpanelMessages);

export const buildDeleteMessage = deleteMessage => ({messageId, dialogId}, routeParams = {}) => (dispatch) => {
    if (dialogId) {
        dispatch(removeMessage({messageId, dialogId}));
    }

    return deleteMessage(null, {
        ...routeParams,
        messageId,
        dialogId,
    });
};

const buildEditMessage = editMessage => ({messageId, dialogId, update}, routeParams = {}) => dispatch => {
    dispatch(
        updateMessage({
            dialogId,
            messageId,
            message: {
                ...update,
                text: update.text,
            },
        }),
    );

    return editMessage({
        text: update.text,
    }, {
        ...routeParams,
        messageId,
        dialogId,
    });
}

export const deleteSocpanelMessage = buildDeleteMessage(supportApi.deleteSocpanelMessage);
export const deleteMessage = buildDeleteMessage(supportApi.deleteMessage);

export const editMessage = buildEditMessage(supportApi.editMessage);
export const editSocpanelMessage = buildEditMessage(supportApi.editSocpanelMessage);

export const updateDialogStatus = (status, dialogId) => (dispatch) => {
    dispatch(changeDialogStatus({dialogId, status}));
    if (status === 'answered') {
        dispatch(dialogAnswered({dialogId}));

        dispatch(dialogOnListUpdated({
            dialog: {
                id: dialogId,
                hello_ivan: 0,
            }
        }));
    }

    return supportApi.updateDialog(dialogId, {status});
};

export const updateDialogPushStatus = (dialogId, scope) => (dispatch) => {
    dispatch(dialogOnListUpdated({
        dialog: {
            id: dialogId,
            hello_ivan: scope,
        }
    }));

    return supportApi.updateDialogOld(dialogId, { hello_ivan: scope });
};


export const sendTyping0 = (dialogId, typing) => (dispatch) => dispatch({
    //type: SEND_WHISPER,
    payload: {
        channel: `SupportDialog.${dialogId}`,
        whisper: 'SupportMessageTyping',
        data: typing,
    },
});

export const sendTyping = (dialogId, userId, typing) => () => {
  if (!dialogId) {
      return;
  }

  return EchoRegistry.getEcho().then((echo) => {
      const currentDialogChannel = `SupportDialog.${dialogId}`;

      echo.private(currentDialogChannel)
          .whisper('SupportMessageTyping', {
              userId,
              typing: {
                  dialogId: dialogId,
                  ...typing,
              },
          });
  });
};

export const delegateDialog = (dialogId, comment) => (dispatch) => supportApi.delegateDialog(dialogId, comment).then((updatedDialog) => {
    dispatch(hideModal());
    dispatch(dialogUpdated({dialogId, updatedDialog}));
});

export const unDelegateDialog = (dialogId) => (dispatch) => supportApi
    .unDelegateDialog(dialogId)
    .then((updatedDialog) => dispatch(dialogUpdated({dialogId, updatedDialog})));

/**
 * Dialog list actions
 */
const lastDialogsLoaded = createAction('Last dialogs loaded');
const dialogAnswered = createAction('Removes answered dialog from the list');

export const getLastDialogs = buildGetLastDialogs(supportApi.getDialogs);
export const resetLastDialogs = createAction('Reset last dialogs');
export const getLastSocpanelDialogs = buildGetLastDialogs(supportApi.getSocpanelDialogs);

export const dialogPanelUpdated = createAction('Dialog panel updated');
export const dialogPanelRemoved = createAction('Dialog panel removed');
export const dialogPanelReactivate = (panelId) => (dispatch) =>
    supportApi.reactivatePanel(panelId).then((values) => dispatch(dialogPanelUpdated({panelId, values})));

export const dialogPanelRemove = (panelId) => (dispatch) =>
    supportApi.removePanel(panelId).then(() => dispatch(dialogPanelUpdated({panelId})));


export { lastDialogsLoaded, dialogAnswered };
