import styled, { css } from "styled-components";
import { color } from "./themes";
export const ultraSmallTextStyle = css `
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 11px;

  color: ${color("textSecondary")};
`;
export const UltraSmallText = styled.p.withConfig({ displayName: "UltraSmallText", componentId: "sc-1em658q" }) `
  ${ultraSmallTextStyle};
`;
