import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ifNotProp, ifProp, prop } from 'styled-tools';
import { TitleText, ParagraphText } from '@socgress/ui';
import { color } from '@socgress/ui/themes';
import { Desktop } from '../../media';
import { ModalHeader } from '../modal-header';
import { zord } from '@socgress/lib/zord';
import { ButtonComponent } from '@socgress/ui/button';
const ModalTemplate = ({ container, desktopWidth, tabletWidth, onClose, children, hideMobilePadding, }) => {
    const Container = container || ModalContainer;
    return (React.createElement(Container, { desktopWidth: desktopWidth, tabletWidth: tabletWidth, hideMobilePadding: hideMobilePadding },
        onClose && (React.createElement(React.Fragment, null,
            React.createElement(Desktop, null,
                React.createElement(Close, { onClick: onClose })))),
        children));
};
ModalTemplate.defaultProps = {
    container: null,
    desktopWidth: 380,
    tabletWidth: 500,
};
export { ModalTemplate };
const ModalContainer = styled.div.attrs((props) => ({
    desktopWidth: `${props.desktopWidth}px`,
    tabletWidth: `${props.tabletWidth}px`,
})).withConfig({ displayName: "ModalContainer", componentId: "sc-wkr3mt" }) `
  width: ${prop('desktopWidth')};
  max-width: 100%;

  position: relative;
  border-radius: 20px;
  background-color: ${color('surfaceBackground')};

  @media (max-width: 768px) {
    width: 100%;
    transition: all 200ms ease;
    max-width: 100%;

    background-color: ${color('surfaceBackground')};
    border-radius: 10px 10px 0 0;
    ${ifNotProp('hideMobilePadding', css `
      padding-bottom: 20px;
    `)}
  }
`;
const ModalContainerDefault = styled(ModalContainer).withConfig({ displayName: "ModalContainerDefault", componentId: "sc-198ar4d" }) `
  background-color: ${color('surfaceBackground')};

  @media (max-width: 768px) {
    border-radius: 10px 10px 0 0;
  }
`;
const WhiteBody = styled.div.withConfig({ displayName: "WhiteBody", componentId: "sc-12q6tid" }) `
  background-color: ${color('surfaceBackgroundSecondary')};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
const Close = styled.a.withConfig({ displayName: "Close", componentId: "sc-1wbblyp" }) `
  width: 40px;
  height: 40px;
  z-index: 1051;

  position: absolute;
  top: -15px;
  right: -15px;
  background: ${color('surfaceBackgroundSecondary')} url(${require('./close-modal-icon.svg')}) no-repeat center center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`;
const BodyContainer = styled.div.withConfig({ displayName: "BodyContainer", componentId: "sc-1wz0zy5" }) `
  overflow: auto;
`;
const ModalTitle = styled(TitleText).withConfig({ displayName: "ModalTitle", componentId: "sc-ypwmgb" }) `
  margin: 26px 30px 16px;
`;
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-1po5k76" }) `
  margin: 30px;

  @media (max-width: 768px) {
    margin: 16px 16px 16px;
  }
`;
const BodyV2 = styled.div.withConfig({ displayName: "BodyV2", componentId: "sc-o05ukk" }) `
  border-radius: 20px;
  background: ${color('surfaceBackground')};
  padding: 24px;
    
  @media (max-width: 768px) {
      padding: 15px;
  }
`;
const ModalBodyContainer = styled(BodyV2).withConfig({ displayName: "ModalBodyContainer", componentId: "sc-1vuobe2" }) `
  ${ifNotProp('noOverFlow', css `
    overflow: auto;
  `)}
`;
const ModalTitleV2 = styled.p.withConfig({ displayName: "ModalTitleV2", componentId: "sc-1fwmq8s" }) `
  //padding-left: 15px;
  margin-bottom: 24px;
  //text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
  font-weight: 650;
  line-height: 24px;
`;
const WhiteBodyV2 = styled(WhiteBody).withConfig({ displayName: "WhiteBodyV2", componentId: "sc-16caw5q" }) `
  background-color: ${color('surfaceBackgroundSecondary')};
  padding: ${zord(4)};
  border-radius: 10px;
`;
const FormContainer = styled.div.withConfig({ displayName: "FormContainer", componentId: "sc-10jvjpb" }) `
  
  & + ${ButtonComponent} {
    margin-top: ${zord(6)};
  }
`;
const MobileHeadContainer = styled.div.withConfig({ displayName: "MobileHeadContainer", componentId: "sc-1pyqo2s" }) `
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const WarnContainer = styled.div.withConfig({ displayName: "WarnContainer", componentId: "sc-1adsykl" }) `
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
  border-radius: inherit;
`;
const WarnIcon = styled.div.withConfig({ displayName: "WarnIcon", componentId: "sc-18ycbmq" }) `
  width: 20px;
  height: 20px;
  mask: url(${require('./static/warn.svg')}) no-repeat;
  mask-size: contain;

  min-width: 20px;
  background-color: ${ifProp('isActive', color('surfaceAttention'), color('surfaceIcon'))};
`;
const ModalWarningMessage = ({ isActive = false, children }) => (React.createElement(WarnContainer, null,
    React.createElement(WarnIcon, { isActive: isActive }),
    React.createElement(ParagraphText, null, children)));
const ModalMobileGlobalStyle = createGlobalStyle `
  :root {
    --rsbs-bg: ${color('surfaceBackground')};
  }
  
  [data-rsbs-header] {
    background-color: ${color('surfaceBackground')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px 0;
    position: relative;
    
    ${ifProp('hasCustomHeader', css `
      padding: 0 !important;

      &:before {
        display: none;
      }
    `)};
  }

  [data-rsbs-overlay] {
    background: ${color('surfaceBackground')};
    z-index: 101;
  }

  [data-rsbs-root] {
    .custom-backdrop {

      overscroll-behavior: none;
      touch-action: none;
      position: fixed;
      right: 0;
      left: 0;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      top: -60px;
      bottom: -60px;
      background: none;
      background-color: rgba(0, 0, 0, 0.4);
      will-change: opacity;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    &[data-rsbs-is-dismissable="false"] .custom-backdrop {
      cursor: ns-resize;
    }

    &[data-rsbs-state="closing"] .custom-backdrop {
      opacity: 0;
    }
  }

  [data-rsbs-header]::before {
    background-color: ${color('surfaceBrand')};
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  // Still used for menu
  .react-modal-sheet-backdrop {
    border: none;

    ${ifProp('isClosing', css `
      pointer-events: none !important;
    `)};
  }

  .react-modal-sheet-container {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: ${color('surfaceBackground')} !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    ${ifProp('isClosing', css `
      pointer-events: none !important;
    `)};
  }

  .react-modal-sheet-header {
    background: ${color('surfaceBackground')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .react-modal-sheet-drag-indicator {
    background: ${color('surfaceBrand')} !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  ${p => (p.isVisible || p.isClosing) && css `
    #app {
        pointer-events: none;
    }
  `};
`;
export { ModalContainer, ModalContainerDefault, ModalBodyContainer, ModalTitle, ModalTitleV2, MobileHeadContainer, BodyContainer, Body, BodyV2, WhiteBody, WhiteBodyV2, FormContainer, Close, ModalHeader, ModalMobileGlobalStyle, ModalWarningMessage, };
