import { useNightMode } from '@socgress/lib/night-mode/use-night-mode';
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ultraSmallTextStyle } from '@socgress/ui';
import { color } from '@socgress/ui/themes';
import { useIsTablet } from '@socgress/lib/common-media';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { ifProp, prop } from 'styled-tools';
import { rgba } from 'polished';
import { showModal } from '@socgress/lib/modal-system';
import { commonModals } from '../../lib/modals';
export const Header = () => {
    const [t] = useTranslation();
    const isTablet = useIsTablet();
    const dispatch = useDispatch();
    const [isNightThemeEnabled, toggleNightMode] = useNightMode();
    const { user } = useSelector(state => state.auth);
    const onPremiumChatClick = () => dispatch(showModal(commonModals.telegramPromotion));
    if (!user) {
        return null;
    }
    const { support_unread: supportUnread } = user;
    if (isTablet) {
        return (React.createElement(HeaderContainer, null,
            React.createElement(Link, { to: "/dashboard" },
                React.createElement(LogoContainer, { src: isNightThemeEnabled ? require('./static/socpanel-white.svg') : require('./static/socpanel.svg'), alt: "Bots Socpanel" })),
            React.createElement(MenuIcon, { icon: isNightThemeEnabled ? require('./static/moon.svg') : require('./static/sun.svg'), onClick: toggleNightMode })));
    }
    return (React.createElement(HeaderContainer, null,
        React.createElement(Link, { to: "/dashboard" },
            React.createElement(LogoContainer, { src: isNightThemeEnabled ? require('./static/socpanel-white.svg') : require('./static/socpanel.svg'), alt: "Bots Socpanel" })),
        React.createElement(MenuContainer, null,
            React.createElement(MenuItem, { to: '/dashboard', exact: true }, t('dashboard')),
            React.createElement(MenuItem, { to: `/payouts/all`, isActive: (_, location) => location.pathname.startsWith('/payouts') },
                t('payouts'),
                user.withdrawals_initial_count > 0 && (React.createElement(PayoutsCounter, { className: "bold" }, user.withdrawals_initial_count))),
            React.createElement(MenuItem, { to: '/developer' }, "API"),
            React.createElement(MenuItem, { to: '/customers' }, t('find_services')),
            React.createElement(MenuItem, { to: '/software' }, t('software')),
            React.createElement(MenuItem, { to: '/support/dialog', style: {
                    display: 'flex',
                    alignItems: 'center',
                } },
                t('support'),
                supportUnread > 0 && (React.createElement(PayoutsCounter, { className: "bold" }, supportUnread))),
            user.completions_total >= 100000 && (React.createElement(MenuItem, { as: "div", onClick: onPremiumChatClick }, t('telegram')))),
        React.createElement(MenuContainer, null,
            React.createElement(MenuIcon, { as: NavLink, to: "/settings/common", icon: require('./static/settings.svg') }),
            React.createElement(MenuIcon, { icon: isNightThemeEnabled ? require('./static/sun.svg') : require('./static/moon.svg'), onClick: toggleNightMode }))));
};
const HeaderContainer = styled.div.withConfig({ displayName: "HeaderContainer", componentId: "sc-11kh8dk" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;

  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`;
const LogoContainer = styled.img.withConfig({ displayName: "LogoContainer", componentId: "sc-ovgexv" }) `
  width: 114px;
  height: 28px;
  object-fit: contain;
`;
const MenuContainer = styled.div.withConfig({ displayName: "MenuContainer", componentId: "sc-1wnnptu" }) `
  display: flex;
  align-items: center;
  gap: 24px;
`;
const MenuItem = styled(NavLink).withConfig({ displayName: "MenuItem", componentId: "sc-10szpov" }) `
  padding: 3px 11px;

  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  align-items: center;
  display: flex;

  border-radius: 10px;

  border: 1px solid transparent;

  transition: 200ms ease-in-out all;

  &.active {
    background: ${color('surfaceBackgroundSecondary')};
  }

  &:hover {
    border-color: ${color('surfaceBorder')};
  }
`;
const PayoutsCounter = styled.span.withConfig({ displayName: "PayoutsCounter", componentId: "sc-cr4ogm" }) `
  margin-left: 5px;
  border-radius: 16px;
  padding: 2px 8px;

  height: 20px;
  display: inline-flex;
  align-items: center;

  ${ultraSmallTextStyle};
  font-weight: 550;
  font-size: 14px;
  color: ${color('textBrand')};

  background: ${p => rgba(color('surfaceBrand')(p), 0.08)};
`;
const MenuIcon = styled.div.withConfig({ displayName: "MenuIcon", componentId: "sc-c4gxp" }) `
  width: 20px;
  height: 20px;

  cursor: pointer;

  mask: url("${prop('icon')}") no-repeat;
  mask-size: contain;

  background-color: ${color('textMain')};

  transition: 200ms ease-in-out background-color;

  &:hover, &.active {
    background-color: ${color('surfaceBrand')};
  }
`;
const NightModeIcon = styled.div.withConfig({ displayName: "NightModeIcon", componentId: "sc-18eo9xe" }) `
  width: 20px;
  height: 20px;

  mask: url('${ifProp('isEnabled', require('./static/moon.svg'), require('./static/sun.svg'))}') no-repeat;
  mask-size: contain;
  transition: background-color 200ms ease-in-out;
  background-color: ${color('surfaceBrand')};
`;
const ThemeButton = styled(Button).withConfig({ displayName: "ThemeButton", componentId: "sc-1truql0" }) `
  padding: 10px;

  @media (min-width: 769px) {
    &&& {
      margin-right: 10px;
    }
  }
`;
