import styled from 'styled-components';
import { commonMedia } from '@socgress/lib/common-media';
import { WhiteCardStyle } from '@socgress/ui/white-card';
import { color } from '../../themes';
import { StyledLink } from '@socgress/ui/header/mobile-menu/menu-item';
export const MenuGroup = styled.div.withConfig({ displayName: "MenuGroup", componentId: "sc-3mgnvq" }) `
  background-color: ${color('surfaceBackgroundSecondary')};

  ${commonMedia.greaterThan('tablet') `
    &:not(:last-of-type):after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid ${color('surfaceLine')}
    }
  `};

  ${commonMedia.lessThan('tablet') `
    &:not(:first-child) {
      margin: 20px 0;
    }
  `};
`;
export const DesktopMenuGroup = styled.div.withConfig({ displayName: "DesktopMenuGroup", componentId: "sc-151com0" }) `
  background-color: ${color('surfaceBackgroundSecondary')};

  ${commonMedia.lessThan('tablet') `
    &:not(:first-child) {
      margin: 20px 0;
    }
  `};
`;
export const CommonMenuWrapper = styled.div.withConfig({ displayName: "CommonMenuWrapper", componentId: "sc-1m283d4" }) `
  background-color: ${color('surfaceBackground')};
  padding: 0 15px 75px;

  ${MenuGroup} {
    ${WhiteCardStyle}
    ${StyledLink} {
      background: none;

      &:after {
        display: none;
      }
    }
  }
`;
